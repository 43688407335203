$(() => {
    $('.faq-link')
        .on('click', function (e) {
            e.preventDefault();
            let articleId = $(this).data('article-id');
            updateResultContainer(articleId);
        })
    ;

    function updateResultContainer(articleId) {
        let articleContent = $('#collapse-' + articleId);

        if (articleContent) {
            $('.articleResult').addClass('d-none');
            articleContent.removeClass('d-none');
        } else {
            console.error('Contenu de l\'article non trouvé pour l\'ID:', articleId);
        }
    }

    $('#showAllCategories').on('click', function() {
        $('#additional-categories').removeClass('collapse');
        $(this).hide();
    });

    $('#reduceAllCat').on('click', function() {
        $('#additional-categories').addClass('collapse');
        $('#showAllCategories').show();
    });

    $(document).on('click', '#showMoreBtn, #hideMoreBtn', function (e) {
        e.preventDefault();

        const categoryId = $(this).data('category-id');
        const $moreContent = $(`#collapseMore${categoryId}`);
        const isShowMore = $(this).attr('id') === 'showMoreBtn';

        if ($moreContent.length) {
            $moreContent.toggleClass('show', isShowMore);
            toggleButtons(categoryId, isShowMore);
        }
    });

    function toggleButtons(categoryId, isExpanded, isAdditional = false) {
        const prefix = isAdditional ? 'Additional' : '';
        $(`#showMoreBtn${prefix}[data-category-id="${categoryId}"]`).toggle(!isExpanded);
        $(`#hideMoreBtn${prefix}[data-category-id="${categoryId}"]`).toggle(isExpanded);
    }

    $(document).ready(function() {
        function toggleButtons(categoryId, isExpanded, isAdditional = false) {
            const prefix = isAdditional ? 'Additional' : '';
            $(`#showMoreBtn${prefix}${categoryId}`).toggle(!isExpanded);
            $(`#hideMoreBtn${prefix}${categoryId}`).toggle(isExpanded);
        }

        function handleToggleButtons(event) {
            event.preventDefault();
            const $button = $(this);
            const categoryId = $button.data('category-id');
            const $moreContent = $(`#collapseMoreCategories${categoryId}`);

            if ($button.attr('id').includes('showMoreBtn')) {
                $moreContent.addClass('show');
                toggleButtons(categoryId, true, $button.attr('id').includes('Additional'));
            } else {
                $moreContent.removeClass('show');
                toggleButtons(categoryId, false, $button.attr('id').includes('Additional'));
            }
        }
        $(document).on('click', '[id^="showMoreBtnAdditional"]', handleToggleButtons);
        $(document).on('click', '[id^="hideMoreBtnAdditional"]', handleToggleButtons);
    });

    $('[data-toggle="collapse"]').on('click', function() {
        const $currentIcon = $(this).find('#angle');

        $('[data-toggle="collapse"] #angle').not($currentIcon).each(function() {
            $(this).removeClass('fa-angle-down').addClass('fa-angle-right');
        });

        $currentIcon.toggleClass('fa-angle-right fa-angle-down');
    });
});
